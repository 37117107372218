import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const Filters = ({ handleFilterChange, handleSliderChange, filters }) => {
    const clearFilters = () => {
        handleFilterChange("packageName", "");
        handleFilterChange("startDate", "");
        handleFilterChange("totalStayDuration", 0);
        handleFilterChange("costRange", [0, 500000]);
        handleFilterChange("distanceFromKabba", [0, 20]);
        handleFilterChange("distanceFromNabwi", [0, 20]);
        handleFilterChange("includeVisa", false);
        handleFilterChange("includeInsurance", false);
        handleFilterChange("includeAirportReception", false);
        handleFilterChange("includeTourGuide", false);
        handleFilterChange("breakfast", false);
        handleFilterChange("lunch", false);
        handleFilterChange("dinner", false);
        handleFilterChange("shuttleService", false);
        handleFilterChange("airConditioned", false);
        handleFilterChange("television", false);
        handleFilterChange("freeWifi", false);
        handleFilterChange("elevator", false);
        handleFilterChange("attachedBathroom", false);
        handleFilterChange("washroomAmenities", false);
        handleFilterChange("englishToilet", false);
        handleFilterChange("indianToilet", false);
        handleFilterChange("laundry", false);
        handleFilterChange("reviewSuperb", false);
        handleFilterChange("reviewVeryGood", false);
        handleFilterChange("reviewGood", false);
        handleFilterChange("reviewPleasant", false);
        handleFilterChange("rating2Stars", false);
        handleFilterChange("rating3Stars", false);
        handleFilterChange("rating4Stars", false);
        handleFilterChange("rating5Stars", false);
        handleFilterChange("ratingUnrated", false);
    };

    const incrementStayDuration = () => {
        const totalStayDuration = parseInt(filters.totalStayDuration || 0);
        handleFilterChange("totalStayDuration", totalStayDuration + 1);
    };

    const decrementStayDuration = () => {
        const totalStayDuration = parseInt(filters.totalStayDuration || 0);
        handleFilterChange("totalStayDuration", Math.max(0, totalStayDuration - 1));
    };

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Filter</h2>
                <button className="text-[#00936c] text-sm" onClick={clearFilters}>Clear all filters</button>
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h3 className="font-semibold mb-4">Popular Filters</h3>
                <div className="mb-4">
                    <label className="block mb-2 text-sm">Package Name:</label>
                    <input
                        type="text"
                        placeholder="Search"
                        value={filters.packageName}
                        onChange={(e) => handleFilterChange("packageName", e.target.value)}
                        className="w-full p-2 text-sm border border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="mb-4">
                <div className="mb-4">
                    <label className="block mb-2 text-sm">Start Date:</label>
                    <input
                        type="date"
                        value={filters.startDate}
                        onChange={(e) => handleFilterChange("startDate", e.target.value)}
                        className="w-full p-2 text-sm border border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="mb-4">
                <div className="flex items-center justify-between">
                    <span className="block text-sm">Duration</span>
                    <div className="flex items-center border border-gray-300 rounded-md px-4 py-2">
                        <button
                            onClick={decrementStayDuration}
                            className="text-gray-500"
                        >
                            &ndash;
                        </button>
                        <span className="mx-2">{filters.totalStayDuration ?? 0}</span>
                        <button
                            onClick={incrementStayDuration}
                            className="text-blue-500"
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>

            <hr className="mb-4" />
            <div className="mb-6">
                <h3 className="font-semibold">Your Budget</h3>
                <div className=" mb-3 text-sm text-gray-600">
                    PKR {filters.costRange[0]} - PKR {filters.costRange[1]}
                </div>
                <Slider
                    range
                    min={0}
                    max={500000}
                    value={filters.costRange}
                    onChange={handleSliderChange}
                    trackStyle={[{ backgroundColor: "#00936c" }]}
                    handleStyle={[{ borderColor: "#00936c" }, { borderColor: "#00936c" }]}
                />
            </div>
            <hr className="mb-4" />
            <div className="mb-6">
                <h3 className="font-semibold">Hotel Distance From Kaaba</h3>
                <div className=" mb-3 text-sm text-gray-600">
                    KM {filters.distanceFromKabba?.[0] ?? 0} - KM {filters.distanceFromKabba?.[1] ?? 20}
                </div>
                <Slider
                    range
                    min={0}
                    max={20}
                    value={filters.distanceFromKabba || [0, 20]}
                    onChange={(value) => handleFilterChange("distanceFromKabba", value)}
                    trackStyle={[{ backgroundColor: "#00936c" }]}
                    handleStyle={[{ borderColor: "#00936c" }, { borderColor: "#00936c" }]}
                />
            </div>
            <div className="mb-6">
                <h3 className="font-semibold">Hotel Distance From Masjid-e-Nabwi</h3>
                <div className=" mb-3 text-sm text-gray-600">
                    KM {filters.distanceFromNabwi?.[0] ?? 0} - KM {filters.distanceFromNabwi?.[1] ?? 20}
                </div>
                <Slider
                    range
                    min={0}
                    max={20}
                    value={filters.distanceFromNabwi || [0, 20]}
                    onChange={(value) => handleFilterChange("distanceFromNabwi", value)}
                    trackStyle={[{ backgroundColor: "#00936c" }]}
                    handleStyle={[{ borderColor: "#00936c" }, { borderColor: "#00936c" }]}
                />
            </div>
            <div className="mb-4">
                <h3 className="font-semibold">Extras</h3>
                {[
                    { label: "Include Visa", key: "is_visa_included" },
                    { label: "Include Insurance", key: "is_insurance_included" },
                    { label: "Include Airport Reception", key: "is_airport_reception_included" },
                    { label: "Include Tour Guide", key: "is_tour_guide_included" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h3 className="font-semibold">Meals</h3>
                {[
                    { label: "Breakfast", key: "is_breakfast_included" },
                    { label: "Lunch", key: "is_lunch_included" },
                    { label: "Dinner", key: "is_dinner_included" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h3 className="font-semibold">Hotel Room Sharing Type</h3>
                {[
                    { label: "Single", key: "cost_for_single" },
                    { label: "Double", key: "cost_for_double" },
                    { label: "Triple", key: "cost_for_triple" },
                    { label: "Quad", key: "cost_for_quad" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            <div className="mb-4">
                <h3 className="font-semibold">Hotel Facilities</h3>
                {[
                    { label: "Shuttle Service", key: "is_shuttle_services_included" },
                    { label: "Air-Conditioned", key: "is_air_condition" },
                    { label: "Television", key: "is_television" },
                    { label: "Free Wifi", key: "is_wifi" },
                    { label: "Elevator", key: "is_elevator" },
                    { label: "Attached Bathroom", key: "is_attach_bathroom" },
                    { label: "Washroom Amenities", key: "is_washroom_amenities" },
                    { label: "English Toilet", key: "is_english_toilet" },
                    { label: "Indian Toilet", key: "is_indian_toilet" },
                    { label: "Laundry", key: "is_laundry" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h3 className="font-semibold">Review score</h3>
                {[
                    { label: "Superb: 4+", key: "reviewSuperb" },
                    { label: "Very good: 3+", key: "reviewVeryGood" },
                    { label: "Good: 2+", key: "reviewGood" },
                    { label: "Pleasant: 1+", key: "reviewPleasant" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            <hr className="mb-4" />
            <div className="mb-4">
                <h3 className="font-semibold">Property rating</h3>
                {[
                    { label: "2 stars", key: "rating2Stars" },
                    { label: "3 stars", key: "rating3Stars" },
                    { label: "4 stars", key: "rating4Stars" },
                    { label: "5 stars", key: "rating5Stars" },
                    { label: "Unrated", key: "ratingUnrated" },
                ].map(({ label, key }) => (
                    <label className="flex items-center mt-2 mb-2 text-sm" key={key}>
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={filters[key]}
                            onChange={(e) => handleFilterChange(key, e.target.checked)}
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Filters;
