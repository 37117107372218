import React, { createContext, useReducer } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import UploadRequiredDocs from "./pages/UserPanel/Booking/BookingDetailedPageComponents/UploadRequiredDocs.jsx";
import Articles from "./pages/UserPanel/ArticlesPage/Articles.js";
import Madinah from "./pages/UserPanel/ArticlesPage/Madinah/Madinah.js";
import Kabba from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Kaaba/Kabba.jsx";
import MakkahClockTower from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/MakkahClockTower/MakkahClockTower.jsx";

// footer
import ContactUs from "./pages/UserPanel/ContactUs/ContactUs.jsx";
import HajjGuide from "./pages/UserPanel/HajjGuide/HajjGuide.jsx";
import UmrahGuide from "./pages/UserPanel/UmrahGuide/UmrahGuide.jsx";

// footer end
import MakkahMuseum from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/MakkahMuseum/MakkahMuseum.jsx";
import MasjidalKhayf from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/MasjidAlKhayf/MasjidAlKhayf.jsx";
import Arafah from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Arafah/Arafah.jsx";
import Mina from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Mina/Mina.jsx";
import Muzdalifah from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Muzdalifah/Muzdalifah.jsx";
import NamirahMosque from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/NamirahMosque/NamirahMosque.jsx";
import SafaMarwa from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/SafaMarwa/SafaMarwa.jsx";
import TaifMainPage from "./pages/UserPanel/ArticlesPage/Taif/TaifMainPage.jsx";
import ApplyForm from "./pages/UserPanel/CareerPage/ApplyForm.jsx";
import Career from "./pages/UserPanel/CareerPage/Career.js";
import JobOne from "./pages/UserPanel/CareerPage/JobOne.jsx";
import Error from "./pages/UserPanel/ErrorPage/Error.js";
import FQA from "./pages/UserPanel/FrequentlyAskedQuestions/FQA.jsx";
import AddressForm from "./pages/UserSetup/Signup/AddressForm.js";
import LoginSignup from "./pages/UserSetup/Login/LoginSignup.js";
import SigninPage from "./pages/UserSetup/Signup/SigninPage.js";
import OtpVerificationPage from "./pages/UserSetup/OTP/otpverification.js";
import DetailPage from "./pages/UserPanel/PackageDetail/DetailPage";
import Hajj from "./pages/UserPanel/Packages/Hajj-Umrah/Hajj";
import Transport from "./pages/UserPanel/Packages/Transport/Transport";
import TransportBooking from "./pages/UserPanel/Packages/Transport/BookingDetail/Booking.js";
import Umrah from "./pages/UserPanel/Packages/Hajj-Umrah/Umrah";
import Ziyarat from "./pages/UserPanel/Packages/Ziyarat/Ziyarat.js";
import PrivacyPolicy from "./pages/UserPanel/PrivacyPolicy/PrivacyPolicy.jsx";
import Profile from "./pages/UserPanel/ProfilePage/Profile";
import AllBookingsInfo from "./pages/UserPanel/Booking/AllBookingsInfo.js";
import BankingInfo from "./pages/UserPanel/ReservePage/BankingInfo.js";
import BookingDetails from "./pages/UserPanel/Booking/BookingDetailPage/BookingDetailedPage.js";
import CreateBookingForm from "./pages/UserPanel/ReservePage/Form.js";
import SubmitReview from "./pages/UserPanel/Booking/BookingDetailedPageComponents/ReviewAndComplaintPages/SubmitReview";
import ReportProblem from "./pages/UserPanel/Booking/BookingDetailedPageComponents/ReviewAndComplaintPages/ReportProblem";
import ComplaintsPage from "./pages/UserPanel/Booking/BookingDetailedPageComponents/ReviewAndComplaintPages/DisplayAllComplaints";
import PaymentForm from "./pages/UserPanel/ReservePage/PaymentForm.js";
import VoucherInfo from "./pages/UserPanel/ReservePage/VoucherInfo.js";
import TermsServices from "./pages/UserPanel/TermsServices/TermsServices.jsx";
import HomePage from "./pages/UserPanel/homepage/homepage.js"; // Adjust the import path according to your file
import Alula from "./pages/UserPanel/ArticlesPage/Alula/Alula.js";
import Alhudaybiyah from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Al-Hudaybiyah/Home";
import Uhud from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/Uhud/Home.jsx";
import BirGhars from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/BigGhars/Home.jsx";
import Gharehira from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Ghar-e-hira/Home.jsx";
import GhareSoor from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Ghar-e-sur/Home";
import MasjidAbuBakr from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidAbuBakr/Home";
import MasjidIjabah from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidEjabah/Home.jsx";
import MasjidAlGhammamah from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidGhamama/Home.jsx";
import MasjidJinn from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/MasjidJinn/Home";
import MasjidJummah from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidJummah/Home.jsx";
import MasjidMiqat from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidMiqat/Home.jsx";
import MasjidMustarah from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidMustarah/Home";
import MasjidNimrah from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/MasjidNimra/Home";
import MasjidQiblatayn from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidQiblatain/Home";
import MasjidQuba from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/MasjidQuba/Home";
import House from "./pages/UserPanel/HolyPlaces/places/MakkahPlaces/Muhammad/Home";
import GardenSalmanFarsi from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/SalmanFarsi/Home.jsx";
import Taif from "./pages/UserPanel/HolyPlaces/places/TaifPages/Taif/Home";
import { initialState, reducer } from "./reducer/usereducer";
import Jeddah from "./pages/UserPanel/ArticlesPage/Jeddah/Jeddah.js";
import Wadijins from "./pages/UserPanel/HolyPlaces/places/MadinahPlaces/Wadijin2/Wadijin.jsx";
import MasjidAbdulahBinAbbas from "./pages/UserPanel/HolyPlaces/places/TaifPages/Abdullah-Bin-Al-Abbas/Home.jsx";
import HalimaSadiaHouse from "./pages/UserPanel/HolyPlaces/places/TaifPages/HalimaSadia/Home.jsx";
import MasjidAlQantara from "./pages/UserPanel/HolyPlaces/places/TaifPages/Masjid-al-Qantara/Home.jsx";
import ALHadaMountain from "./pages/UserPanel/HolyPlaces/places/TaifPages/Al-Hada-Mountain/Home.jsx";
import ShafaMountain from "./pages/UserPanel/HolyPlaces/places/TaifPages/Shafa-Mountain/Home.jsx";
import ArruddafPark from "./pages/UserPanel/HolyPlaces/places/TaifPages/Arruddaf-Park/Home.jsx";
import ShubraPalace from "./pages/UserPanel/HolyPlaces/places/TaifPages/ShubraPalace/Home.jsx";
import TaifRoseFields from "./pages/UserPanel/HolyPlaces/places/TaifPages/RoseFields/Home.jsx";
import SaiysadPark from "./pages/UserPanel/HolyPlaces/places/TaifPages/Saiysad-Park/Home.jsx";
import Taifzoo from "./pages/UserPanel/HolyPlaces/places/TaifPages/TaifZoo/Home.jsx";
import KingFahad from "./pages/UserPanel/HolyPlaces/places/TaifPages/KingFahad/Home.jsx";
// jeddah holy sites imports
import AlRehmanMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/AlRehmanMosque/AlRehmanMosque.jsx";
import KhadijahMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/KhadijahMosque/KhadijahMosque.jsx";
import HassanMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/HassanMosque/HassanMosque.jsx";
import KhalidMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/KhalidMosque/KhalidMosque.jsx";
import TurkeyMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/TurkeyMosque/TurkeyMosque.jsx";
import AbuBakarMosque from "./pages/UserPanel/HolyPlaces/places/JeddahPages/AbuBakarMosque/AbuBakarMosque.jsx";
// jeddah attractions places imports
import NasifHouseMuseum from "./pages/UserPanel/HolyPlaces/places/JeddahPages/Nassif-House-Museum/Home.jsx";
import GlobeRoundAbout from "./pages/UserPanel/HolyPlaces/places/JeddahPages/Globe-Roundabout/Home.jsx";
import SouqAlAlawi from "./pages/UserPanel/HolyPlaces/places/JeddahPages/Souq-al-Alawi/Home.jsx";
import TaybatMuseum from "./pages/UserPanel/HolyPlaces/places/JeddahPages/TaybatMuseum/Home.jsx";
import FlagPole from "./pages/UserPanel/HolyPlaces/places/JeddahPages/FlagPole/Home.jsx";
import AlBalad from "./pages/UserPanel/HolyPlaces/places/JeddahPages/Al-Balad/Home.jsx";
import AboutUs from "./pages/UserPanel/AboutUs/AboutUs.jsx";
import HelpCenter from "./pages/UserPanel/SupportPage/HelpCenter.jsx";
import CommunityPoliciesPage from "./pages/UserPanel/SupportPage/Guest/CommunityPolicies.jsx";
import ExploreMore from "./pages/UserPanel/SupportPage/Guest/ExploreMore.jsx";
import ManageAccount from "./pages/UserPanel/SupportPage/Guest/ManageAccount.jsx";
import Reservation from "./pages/UserPanel/SupportPage/Guest/Reservation.jsx";
import GetStarted from "./pages/UserPanel/SupportPage/Guest/GetStarted.jsx";
import GettingPaid from "./pages/UserPanel/SupportPage/Host/GettingPaid.jsx";
import HelpWithHosting from "./pages/UserPanel/SupportPage/Host/HelpWithHosting.jsx";
import EVisa from "./pages/UserPanel/EVisaPage/EVisa.js";
import VisaProcess from "./pages/UserPanel/EVisaPage/VisaProcess.js";

//Profile management pages
import AccountSettings from "./pages/AccountManagement/AccountSettings";
import RewardsWallet from "./pages/AccountManagement/RewardsWallet";
import SavedListComponent from "./pages/AccountManagement/SavedList";

import MainPage from "./pages/AccountManagement/AccountSettingsPages/MainPage";
import PersonalDetailsPage from "./pages/AccountManagement/AccountSettingsPages/All-Pages/PersonalDetailsPage";
import SecurityPage from "./pages/AccountManagement/AccountSettingsPages/All-Pages/SecurityPage";
import EmailNotificationsPage from "./pages/AccountManagement/AccountSettingsPages/All-Pages/EmailNotificationsPage";

import MissionVision from "./pages/UserPanel/MissionVision/MissionVision.jsx";
import CoreValues from "./pages/UserPanel/CoreValues/CoreValues.jsx";
import HuzPartnerPage from "./pages/UserPanel/HuzPartner/HuzPartnerPage.jsx";
import HowHuzWorks from "./pages/UserPanel/HowHuzWorks/HowHuzWorks.jsx";
import Hegra from "./pages/UserPanel/HolyPlaces/places/AlUla/Hegra/Hegra.jsx";
import AlulaOldTown from "./pages/UserPanel/HolyPlaces/places/AlUla/OldTown/AlulaOldTown.jsx";
import JabalIkmah from "./pages/UserPanel/HolyPlaces/places/AlUla/Jabalikmah/JabalIkmah.jsx";
import Dadan from "./pages/UserPanel/HolyPlaces/places/AlUla/Dadan/Dadan.jsx";
import HijazRailwayStation from "./pages/UserPanel/HolyPlaces/places/AlUla/HijazRailwayStation/HijazRailwayStation.jsx";

import { CurrencyProvider } from "./utility/CurrencyContext";

export const AuthContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // localStorage.clear();
  return (
    <>
      <AuthContext.Provider value={{ state, dispatch }}>
        <CurrencyProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/articles/makkah" element={<Articles />} />
                <Route path="/articles/taif" element={<TaifMainPage />} />
                <Route path="/articles/jeddah" element={<Jeddah />} />
                <Route path="/hajj" element={<Hajj />} />
                <Route path="/umrah" element={<Umrah />} />
                <Route path="/ziyarat" element={<Ziyarat />} />
                <Route path="/transport" element={<Transport />} />
                <Route
                  path="/tranport/package/deal"
                  element={<TransportBooking />}
                />
                <Route path="/articles/madinah" element={<Madinah />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/career" element={<Career />} />
                <Route path="/job-one" element={<JobOne />} />
                <Route path="/apply-form" element={<ApplyForm />} />
                <Route path="/error" element={<Error />} />
                <Route path="/detailpage" element={<DetailPage />} />
                <Route path="/reserveform" element={<CreateBookingForm />} />
                <Route path="/paymentform" element={<PaymentForm />} />
                {/* Other components like Footer, etc can go here */}
                {/* Makkah places */}
                <Route
                  path="/articles/makkah/gar-e-hira"
                  element={<Gharehira />}
                />
                <Route
                  path="/articles/makkah/prophet-house"
                  element={<House />}
                />
                <Route
                  path="/articles/makkah/ghar-e-soor"
                  element={<GhareSoor />}
                />
                <Route
                  path="/articles/makkah/masjid-jinn"
                  element={<MasjidJinn />}
                />
                <Route
                  path="/articles/makkah/masjid-nimrah"
                  element={<MasjidNimrah />}
                />
                <Route path="/articles/makkah/taif" element={<Taif />} />
                <Route
                  path="/articles/makkah/al-hudaybiyah"
                  element={<Alhudaybiyah />}
                />
                {/* Madina places */}
                <Route
                  path="/articles/madinah/wadi-jin"
                  element={<Wadijins />}
                />
                {/* <Route path="/articles/madinah/wadi-jin" element={<Wadijin />} /> */}
                <Route
                  path="/articles/madinah/uhud-mountain"
                  element={<Uhud />}
                />
                <Route
                  path="/articles/madinah/bir-ghars"
                  element={<BirGhars />}
                />
                <Route
                  path="/articles/madinah/masjid-abu-bakr"
                  element={<MasjidAbuBakr />}
                />
                <Route
                  path="/articles/madinah/masjid-ijabah"
                  element={<MasjidIjabah />}
                />
                <Route
                  path="/articles/madinah/masjid-al-ghammamah"
                  element={<MasjidAlGhammamah />}
                />
                <Route
                  path="/articles/madinah/masjid-jummah"
                  element={<MasjidJummah />}
                />
                <Route
                  path="/articles/madinah/masjid-miqat"
                  element={<MasjidMiqat />}
                />
                <Route
                  path="/articles/madinah/masjid-mustarah"
                  element={<MasjidMustarah />}
                />
                <Route
                  path="/articles/madinah/masjid-qiblatayn"
                  element={<MasjidQiblatayn />}
                />{" "}
                <Route
                  path="/articles/madinah/masjid-quba"
                  element={<MasjidQuba />}
                />
                <Route
                  path="/articles/madinah/garden-of-salman-farsi"
                  element={<GardenSalmanFarsi />}
                />
                {/* Iraq Places */}
                <Route
                  path="/articles/makkah/holysites/mina"
                  element={<Mina />}
                />
                <Route
                  path="/articles/makkah/holysites/arafah"
                  element={<Arafah />}
                />
                <Route
                  path="/articles/makkah/holysites/safa-marwa"
                  element={<SafaMarwa />}
                />
                <Route
                  path="/articles/makkah/holysites/muzdalifah"
                  element={<Muzdalifah />}
                />
                <Route
                  path="/articles/makkah/holysites/namirah-mosque"
                  element={<NamirahMosque />}
                />
                <Route
                  path="/articles/makkah/holysites/masjid-al-Khayf"
                  element={<MasjidalKhayf />}
                />
                <Route
                  path="/articles/makkah/holysites/masjid-jinn"
                  element={<MasjidJinn />}
                />
                <Route
                  path="/articles/makkah/holysites/kabba"
                  element={<Kabba />}
                />
                <Route
                  path="/articles/makkah/holysites/makkah-museum"
                  element={<MakkahMuseum />}
                />
                <Route
                  path="/articles/makkah/holysites/makkah-clock-tower"
                  element={<MakkahClockTower />}
                />
                <Route
                  path="/articles/taif/holysites/masjid-abdullah-bin-abbas"
                  element={<MasjidAbdulahBinAbbas />}
                />
                <Route
                  path="/articles/taif/holysites/al-hada-mountain"
                  element={<ALHadaMountain />}
                />
                <Route
                  path="/articles/taif/holysites/shafa-mountain"
                  element={<ShafaMountain />}
                />
                <Route
                  path="/articles/taif/attractions/arruddaf-park"
                  element={<ArruddafPark />}
                />
                <Route
                  path="/articles/taif/attractions/shubra-palace"
                  element={<ShubraPalace />}
                />
                <Route
                  path="/articles/taif/attractions/taif-rose-fields"
                  element={<TaifRoseFields />}
                />
                <Route
                  path="/articles/taif/attractions/saiysad-park"
                  element={<SaiysadPark />}
                />
                <Route
                  path="/articles/taif/attractions/taif-zoo"
                  element={<Taifzoo />}
                />
                <Route
                  path="/articles/taif/attractions/king-fahad"
                  element={<KingFahad />}
                />
                <Route
                  path="/articles/taif/holysites/halima-sadia-house"
                  element={<HalimaSadiaHouse />}
                />
                <Route
                  path="/articles/taif/holysites/masjid-al-qantara"
                  element={<MasjidAlQantara />}
                />
                <Route
                  path="/articles/jeddah/holysites/al-rehman-mosque"
                  element={<AlRehmanMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/khadijah-mosque"
                  element={<KhadijahMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/hassan-mosque"
                  element={<HassanMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/khalid-mosque"
                  element={<KhalidMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/turkey-mosque"
                  element={<TurkeyMosque />}
                />{" "}
                <Route
                  path="/articles/jeddah/holysites/abu-bakar-mosque"
                  element={<AbuBakarMosque />}
                />
                <Route
                  path="/articles/jeddah/attractions/nasif-house-museum"
                  element={<NasifHouseMuseum />}
                />
                <Route
                  path="/articles/jeddah/attractions/globe-round-about"
                  element={<GlobeRoundAbout />}
                />
                <Route
                  path="/articles/jeddah/attractions/souq-al-alawi"
                  element={<SouqAlAlawi />}
                />
                <Route
                  path="/articles/jeddah/attractions/taybat-museum"
                  element={<TaybatMuseum />}
                />
                <Route
                  path="/articles/jeddah/attractions/flag-pole"
                  element={<FlagPole />}
                />
                <Route
                  path="/articles/jeddah/attractions/al-balad"
                  element={<AlBalad />}
                />
                <Route path="/login/signup" element={<SigninPage />} />
                <Route path="/login" element={<LoginSignup />} />
                <Route
                  path="/otpverification"
                  element={<OtpVerificationPage />}
                />
                <Route path="/bankingDetail" element={<BankingInfo />} />
                <Route path="/voucherDetail" element={<VoucherInfo />} />
                <Route
                  path="/booking_details/:booking_number"
                  element={<BookingDetails />}
                />
                <Route path="/allbookingsinfo" element={<AllBookingsInfo />} />
                <Route path="/allbookingsinfo" element={<AllBookingsInfo />} />
                {/* <Route path="/passportdetail" element={<PassportDetail />} /> */}
                <Route path="/addressform" element={<AddressForm />} />
                <Route path="/frequently-asked-questions" element={<FQA />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/mission-vision" element={<MissionVision />} />
                <Route path="/core-values" element={<CoreValues />} />
                <Route path="/huz-partner" element={<HuzPartnerPage />} />
                <Route path="/how-huz-works" element={<HowHuzWorks />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/hajj-guide" element={<HajjGuide />} />
                <Route path="/umrah-guide" element={<UmrahGuide />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route path="/terms-services" element={<TermsServices />} />
                <Route path="/articles/alula" element={<Alula />} />
                <Route path="/articles/alula/hegra" element={<Hegra />} />
                <Route
                  path="/articles/alula/jabal-ikmah"
                  element={<JabalIkmah />}
                />
                <Route path="/articles/alula/dadan" element={<Dadan />} />
                <Route
                  path="/articles/alula/hijaz-railway-station"
                  element={<HijazRailwayStation />}
                />
                <Route
                  path="/articles/alula/alula-old-town"
                  element={<AlulaOldTown />}
                />
                <Route
                  path="/community-policies"
                  element={<CommunityPoliciesPage />}
                />
                <Route path="/explore-more" element={<ExploreMore />} />
                <Route path="/manage-account" element={<ManageAccount />} />
                <Route path="/reservation" element={<Reservation />} />
                <Route path="/get-started" element={<GetStarted />} />
                <Route path="/getting-paid" element={<GettingPaid />} />
                <Route path="/help-hosting" element={<HelpWithHosting />} />
                <Route
                  path="/upload-documents"
                  element={<UploadRequiredDocs />}
                />
                <Route path="/report-problem" element={<ReportProblem />} />
                <Route path="/submit-review" element={<SubmitReview />} />
                <Route path="/all-complaints" element={<ComplaintsPage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/evisa-services" element={<EVisa />} />
                <Route
                  path="/evisa-services/confirmation-process"
                  element={<VisaProcess />}
                />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route
                  path="/account-rewards&wallet"
                  element={<RewardsWallet />}
                />
                <Route
                  path="/account-savedlist"
                  element={<SavedListComponent />}
                />
                <Route
                  path="/personal-details"
                  element={<MainPage currentPath="/personal-details" />}
                >
                  <Route index element={<PersonalDetailsPage />} />
                </Route>
                <Route
                  path="/security"
                  element={<MainPage currentPath="/security" />}
                >
                  <Route index element={<SecurityPage title="Security" />} />
                </Route>
                <Route
                  path="/email-notifications"
                  element={<MainPage currentPath="/email-notifications" />}
                >
                  <Route
                    index
                    element={
                      <EmailNotificationsPage title="Email notifications" />
                    }
                  />
                </Route>
              </Routes>
            </div>
          </Router>
        </CurrencyProvider>
      </AuthContext.Provider>
    </>
  );
}
export default App;
