import React, { useEffect } from "react";
import Footer from "../../../components/footer.js";
import Subscription from "../../../components/subscription.js";
import ExploreMore from "./ExploreMoreSection/ExploreMore";
import Package from "./Package.js";
import TrendingDestinations from "./Trendingdestinations.js";
import HeaderComponent from "./Header/header.js";
import InspirationCards from "./inspiration.js";
import OffersSlider from "./offers.js";
import SearchBar from "./searchbar.js";

const HomePage = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <div>
      {/* <Header/> */}
      <HeaderComponent />
      {/* Rest of the homepage content goes here */}
      <main>
        <div className="relative w-[95%] lg:w-full mx-auto mt-[-35px] md:mt-[-40px] z-10">
          <SearchBar />
        </div>
        <OffersSlider />
        <TrendingDestinations />
        <Package />
        <ExploreMore />
        <InspirationCards />
      </main>
      <Subscription />
      <Footer />
    </div>
  );
};

export default HomePage;
