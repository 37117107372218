import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range'; // Single date picker
import { format } from 'date-fns';
import { AiOutlineClose } from 'react-icons/ai'; // for close icon

const MobileSearchBar = () => {
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [showDateModal, setShowDateModal] = useState(false);

  const toggleDateModal = () => {
    setShowDateModal(!showDateModal);
  };

  const handleCheckInSelect = (date) => {
    setCheckInDate(date);
  };

  const handleCheckOutSelect = (date) => {
    setCheckOutDate(date);
  };

  const formattedCheckInDate = format(checkInDate, 'MM/dd/yyyy');
  const formattedCheckOutDate = format(checkOutDate, 'MM/dd/yyyy');

  const dateRangeValue = `${formattedCheckInDate} — ${formattedCheckOutDate}`;

  return (
    <div className="lg:hidden flex flex-col items-center w-full px-4 py-2 relative">
      <div className="bg-white shadow-lg w-full rounded-lg overflow-hidden border-2 border-[#00936C] relative">
        {/* Location Input */}
        <div className="flex items-center border-b px-4 py-2">
          <input
            type="text"
            placeholder="Where are you going?"
            className="border-0 text-sm bg-transparent outline-none text-gray-700 w-full"
          />
        </div>

        {/* Date Selection */}
        <div className="flex items-center border-b px-4 py-2 cursor-pointer" onClick={toggleDateModal}>
          <span className="text-black text-sm px-4 ">Check-in date — Check-out date</span>
          <input
            type="text"
            read-only
            className="border-0 text-sm bg-transparent outline-none text-gray-700 w-full"
            value={dateRangeValue}
            placeholder="Check-in date — Check-out date"
          />
        </div>

        {showDateModal && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
            onClick={toggleDateModal} // close on background click
          >
            <div
              className="bg-white p-4 rounded-lg shadow-lg relative"
              onClick={(e) => e.stopPropagation()} // prevent closing on modal click
            >
              <div className="flex justify-between items-center pb-2">
                <h2 className="text-lg font-semibold">Select Dates</h2>
                <button onClick={toggleDateModal} className="text-gray-700 hover:text-black">
                  <AiOutlineClose size={24} />
                </button>
              </div>

              {/* Separate calendars for check-in and check-out */}
              <div className="flex flex-col">
                <h3 className="text-gray-700 font-semibold">Check-in</h3>
                <Calendar
                  date={checkInDate}
                  onChange={handleCheckInSelect}
                />
                <h3 className="text-gray-700 font-semibold mt-4">Check-out</h3>
                <Calendar
                  date={checkOutDate}
                  onChange={handleCheckOutSelect}
                />
              </div>
            </div>
          </div>
        )}

        {/* Guest Number Input */}
        <div className="flex items-center border-b px-4 py-2">
          <input
            type="number"
            className="border-0 text-sm bg-transparent outline-none px-4  text-gray-700 w-full"
            placeholder="2 adults · 0 children · 1 room"
          />
        </div>

        {/* Search Button */}
        <div className="flex items-center w-full">
          <button className="bg-[#00936C] text-white py-3 justify-center w-full shadow-md flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchBar;
