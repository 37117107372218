import React, { useContext, useState } from "react";
import bg from "../../../assets/herobg.svg";
import logo from "../../../assets/logo.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import Dropdown from "../../../components/Navbar/Dropdown";
import Currency from "../../../components/Navbar/Currency";
import { CurrencyContext } from "../../../utility/CurrencyContext";

const DetailHeaderComponent = ({ isModalOpen, setIsModalOpen }) => {
  const path = window.location.pathname;
  const backgroundImageStyle = {
    backgroundImage: `url(${bg})`,
    backgroundColor: "#E5F1EE",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative", // Make sure this is set to relative
  };

  // Slider settings for responsive view
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false, // This will remove the arrows from the slider
    slidesToShow: 4, // Show 2.5 items for peeking next item
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };
  const sliderContainerStyle = {
    overflow: "visible", // This will allow items to be visible even if they overflow their container
  };
  const slideItemClass = ""; // Tailwind class for horizontal padding, adjust the value as needed
  const [isOpen, setIsOpen] = useState(false);
  const { selectedCurrency } = useContext(CurrencyContext);

  const handleClick = () => {
    setIsOpen(true);
  };
  return (
    <header style={backgroundImageStyle}>
      <div className="mx-auto w-[85%] pb-4 ">
        <div className="flex flex-col items-start pt-4">
          <div className="flex justify-between w-full">
            <NavLink to={"/"}>
              <div className="flex items-center space-x-6">
                <img src={logo} alt="Logo" className="lg:h-14 h-10" />
              </div>
            </NavLink>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleClick}
                className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden hover:bg-[#dddddd] transition duration-150"
              >
                {selectedCurrency}
              </button>
              <a
                href="http://partners.hajjumrah.co/"
                className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden"
              >
                Become A Partner
              </a>
              {!isOpen && !isModalOpen && (
                <Dropdown isOpenDropdown={handleClick} />
              )}
            </div>
          </div>
          <div className="lg:flex flex-row lg:justify-start mt-4 w-full">
            <div className="block lg:hidden" style={sliderContainerStyle}>
              <Slider {...settings} className="block lg:hidden">
                <div className={slideItemClass}>
                  <a
                    href="/hajj"
                    className={`${
                      path === "/hajj"
                        ? "text-gray-800 text-lg underline"
                        : "hover:text-[#00936C] whitespace-nowrap"
                    }`}
                  >
                    Hajj
                  </a>
                </div>
                <div className={slideItemClass}>
                  <a
                    href="/umrah"
                    className={`${
                      path === "/umrah"
                        ? "text-gray-800 text-lg underline"
                        : "hover:text-[#00936C] whitespace-nowrap"
                    }`}
                  >
                    Umrah
                  </a>
                </div>
                <div className={slideItemClass}>
                  <a
                    href="/error"
                    className={`${
                      path === "/error"
                        ? "text-gray-800 text-lg underline"
                        : "hover:text-[#00936C] whitespace-nowrap"
                    }`}
                  >
                    Ziyarat
                  </a>
                </div>
                <div className={`${slideItemClass}`}>
                  <a
                    href="/evisa-services"
                    className="hover:text-[#00936C] whitespace-nowrap"
                  >
                    Visa Services
                  </a>
                </div>
                <div className={`${slideItemClass} px-11`}>
                  <a
                    href="/transport"
                    className={`${
                      path === "/transport"
                        ? "text-gray-800 text-lg underline"
                        : "hover:text-[#00936C] whitespace-nowrap"
                    }`}
                  >
                    Tour & Transport
                  </a>
                </div>
              </Slider>
            </div>
            <nav className="hidden lg:flex space-x-6 text-[#827F7F]">
              <a
                href="/hajj"
                // className="hover:text-[#00936C]"
                className={`${
                  path === "/hajj"
                    ? "text-gray-800 text-lg underline"
                    : "hover:text-[#00936C] whitespace-nowrap"
                }`}
              >
                Hajj
              </a>
              <a
                href="/umrah"
                className={`${
                  path === "/umrah"
                    ? "text-gray-800 text-lg underline"
                    : path === "/"
                    ? "text-gray-800 text-lg underline"
                    : "hover:text-[#00936C] whitespace-nowrap"
                }`}
              >
                Umrah
              </a>
              <a
                href="/error"
                className={`${
                  path === "/error"
                    ? "text-gray-800 text-lg underline"
                    : "hover:text-[#00936C] whitespace-nowrap"
                }`}
              >
                Ziyarat
              </a>
              <a href="/evisa-services" className="hover:text-[#00936C]">
                Visa Services
              </a>
              <a
                href="/transport"
                className={`${
                  path === "/transport"
                    ? "text-gray-800 text-lg underline"
                    : "hover:text-[#00936C] whitespace-nowrap"
                }`}
              >
                Tour & Transport
              </a>
            </nav>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed pt-[1000px] md:pt-[300px] xl:pt-[100px] lg:pb-5 overflow-y-auto inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[85%] p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Select Your Currency</h2>
            <Currency setIsOpen={setIsOpen} />{" "}
          </div>
        </div>
      )}
    </header>
  );
};

export default DetailHeaderComponent;
