import React from "react";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";

function Modal({
  show,
  onClose,
  adults,
  setAdults,
  children,
  setChildren,
  infant,
  setInfant,
  onSave,
}) {
  const incrementAdults = () => {
    setAdults(adults + 1);
  };

  const decrementAdults = () => {
    if (adults > 0) {
      setAdults(adults - 1);
    }
  };

  const incrementChildren = () => {
    setChildren(children + 1);
  };

  const decrementChildren = () => {
    if (children > 0) {
      setChildren(children - 1);
    }
  };
  const incrementInfant = () => {
    setInfant(infant + 1);
  };

  const decrementInfant = () => {
    if (infant > 0) {
      setInfant(infant - 1);
    }
  };

  const handleSave = () => {
    onSave();
    onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="absolute bg-white rounded-lg shadow-lg px-5 w-[330px] py-10 z-50 mt-2">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Guests</h2>
        <button onClick={onClose} className="text-gray-500">
          ✕
        </button>
      </div>
      <p className="text-sm text-gray-500 mb-4">
        This place shows the number of guests, not including infants. Pets
        aren't allowed.
      </p>
      <div className="mb-4 space-y-4">
        <div className="flex justify-between items-center mb-2">
          <div>
            <p className="font-semibold">Adults</p>
            <p className="text-sm text-gray-500">Age 13+</p>
          </div>
          <div className="flex items-center">
            <button
              onClick={decrementAdults}
              className={` ${
                adults === 0 ? "text-gray-300" : "text-[#484848] opacity-80"
              } size-[25px]`}
              disabled={adults === 0}
            >
              <CiCircleMinus className="size-[25px]" />
            </button>
            <span className="mx-2">{adults}</span>
            <button
              onClick={incrementAdults}
              className="text-[#484848] opacity-80 size-[25px]"
            >
              <CiCirclePlus className="size-[25px]" />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-semibold">Children</p>
            <p className="text-sm text-gray-500">Under 12</p>
          </div>
          <div className="flex items-center">
            <button
              onClick={decrementChildren}
              className={` ${
                children === 0 ? "text-gray-300" : "text-[#484848] opacity-80"
              } `}
              disabled={children === 0}
            >
              <CiCircleMinus className="size-[25px]" />
            </button>
            <span className="mx-2">{children}</span>
            <button
              onClick={incrementChildren}
              className="text-[#484848] opacity-80 size-[25px]"
            >
              <CiCirclePlus className="size-[25px]" />
            </button>
          </div>
        </div>{" "}
        <div className="flex justify-between items-center">
          <div>
            <p className="font-semibold">Infant</p>
            <p className="text-sm text-gray-500">
              Children 2 years or less than 2 years{" "}
            </p>
          </div>
          <div className="flex items-center">
            <button
              onClick={decrementInfant}
              className={` ${
                infant === 0 ? "text-gray-300" : "text-[#484848] opacity-80"
              } `}
              disabled={infant === 0}
            >
              <CiCircleMinus className="size-[25px]" />
            </button>
            <span className="mx-2">{infant}</span>
            <button
              onClick={incrementInfant}
              className="text-[#484848] opacity-80 size-[25px]"
            >
              <CiCirclePlus className="size-[25px]" />
            </button>
          </div>
        </div>
      </div>
      <hr className="py-2.5" />
      <div className="flex justify-between items-center">
        <button onClick={onClose} className="text-gray-500">
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="px-5 py-2 bg-[#00936C] text-white rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default Modal;
