// PackagesPage.js
import React, { useState } from "react";
import packagesData from "./packagesData.json";
import PackageCard from "./PackageCard";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";

const PackagesPage = ({ isModalOpen, setIsModalOpen }) => {
  const [filters, setFilters] = useState({
    packageName: "",
    startDate: "",
    endDate: "",
    packageType: "",
    costRange: [0, 500000],
    includeVisa: false,
    includeInsurance: false,
    includeAirportReception: false,
    includeMeal: false,
    includeTourGuide: false,
    nightsInMecca: "",
    nightsInMadina: "",
    distanceFromKaabaMecca: "",
    distanceFromKaabaMadina: "",
  });

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle changes in the filters
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  // Handle slider change
  const handleSliderChange = (value) => {
    setFilters((prev) => ({ ...prev, costRange: value }));
  };

  // Apply filters to package data
  const filteredPackages = packagesData.results.filter((pkg) => {
    const matchesName =
      filters.packageName === "" ||
      pkg.package_name
        .toLowerCase()
        .includes(filters.packageName.toLowerCase());
    const matchesType =
      filters.packageType === "" ||
      pkg.package_type.includes(filters.packageType);
    const matchesMinCost = pkg.package_cost >= filters.costRange[0];
    const matchesMaxCost = pkg.package_cost <= filters.costRange[1];
    const matchesVisa = !filters.includeVisa || pkg.is_visa_included;
    const matchesInsurance =
      !filters.includeInsurance || pkg.is_insurance_included;
    const matchesReception =
      !filters.includeAirportReception || pkg.is_airport_reception_included;
    const matchesMeal = !filters.includeMeal || pkg.is_meal_included;
    const matchesTourGuide =
      !filters.includeTourGuide || pkg.is_tour_guide_included;
    const matchesNightsInMecca =
      filters.nightsInMecca === "" ||
      pkg.nights_in_mecca === parseInt(filters.nightsInMecca);
    const matchesNightsInMadina =
      filters.nightsInMadina === "" ||
      pkg.nights_in_madina === parseInt(filters.nightsInMadina);
    return (
      matchesName &&
      matchesType &&
      matchesMinCost &&
      matchesMaxCost &&
      matchesVisa &&
      matchesInsurance &&
      matchesReception &&
      matchesMeal &&
      matchesTourGuide &&
      matchesNightsInMecca &&
      matchesNightsInMadina
    );
  });

  return (
    <div className="flex flex-col lg:flex-row gap-4 mx-auto w-[85%] py-10">
      {/* Filter Button for Mobile */}
      <button
        className="lg:hidden bg-[#00936c] text-white py-2 px-4 rounded-lg mb-4"
        onClick={() => setIsModalOpen(true)}
      >
        Filter
      </button>

      {/* Filter Card for Desktop Only */}
      <div className="hidden lg:block bg-[#f0f6f4] shadow-lg rounded-lg p-6 w-full border lg:w-1/4 lg:h-auto lg:max-h-[900px] lg:sticky lg:top-4">
        {renderFilters(handleFilterChange, handleSliderChange, filters)}
      </div>

      {/* Packages Display */}
      <div className="flex-grow grid-cols-1 space-y-6">
        {filteredPackages.map((pkg) => (
          <PackageCard key={pkg.huz_token} pkg={pkg} />
        ))}
      </div>

      {/* Modal for Mobile Filter */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[90%] max-w-md max-h-screen overflow-y-auto p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsModalOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Filters</h2>
            {renderFilters(handleFilterChange, handleSliderChange, filters)}
          </div>
        </div>
      )}
    </div>
  );
};

// Function to render filters UI
const renderFilters = (handleFilterChange, handleSliderChange, filters) => {
  return (
    <>
      {/* Package Name */}
      <div className="mb-4 ">
        <label className="block mb-2 font-semibold text-sm">
          Package Name:
        </label>
        <input
          type="text"
          placeholder="Search"
          value={filters.packageName}
          onChange={(e) => handleFilterChange("packageName", e.target.value)}
          className="w-full p-2 text-sm border border-gray-300 rounded-md"
        />
      </div>

      {/* Date Inputs */}
      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">Start date:</label>
        <input
          type="date"
          value={filters.startDate}
          onChange={(e) => handleFilterChange("startDate", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">End date:</label>
        <input
          type="date"
          value={filters.endDate}
          onChange={(e) => handleFilterChange("endDate", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
        />
      </div>

      {/* Nights in Mecca */}
      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">
          Nights in Mecca:
        </label>
        <input
          type="number"
          placeholder="Number of Nights"
          value={filters.nightsInMecca}
          onChange={(e) => handleFilterChange("nightsInMecca", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
        />
      </div>

      {/* Nights in Madina */}
      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">
          Nights in Madina:
        </label>
        <input
          type="number"
          placeholder="Number of Nights"
          value={filters.nightsInMadina}
          onChange={(e) => handleFilterChange("nightsInMadina", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
        />
      </div>
      {/* Package Type */}
      <div className="mb-4">
        <label className="block mb-2 font-semibold text-sm">Select type:</label>
        <select
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
          onChange={(e) => handleFilterChange("packageType", e.target.value)}
        >
          <option value="">All</option>
          <option value="Umrah">Umrah</option>
          <option value="Hajj">Hajj</option>
        </select>
      </div>

      {/* Budget Slider */}
      <div className="mb-6">
        <label className="block mb-2 font-semibold text-sm">
          Your Budget (per package):
        </label>
        <div className="px-2 mb-3 text-sm text-gray-600 ">
          PKR {filters.costRange[0]} - PKR {filters.costRange[1]}
        </div>
        <Slider
          range
          min={0}
          max={500000}
          value={filters.costRange}
          onChange={handleSliderChange}
          trackStyle={[{ backgroundColor: "#007bff" }]}
          handleStyle={[{ borderColor: "#007bff" }, { borderColor: "#007bff" }]}
        />
      </div>

      {/* Feature Filters */}
      <div className="mb-4">
        <label className="flex items-center mb-2 text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.includeVisa}
            onChange={(e) =>
              handleFilterChange("includeVisa", e.target.checked)
            }
          />
          Include Visa
        </label>
        <label className="flex items-center mb-2 text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.includeInsurance}
            onChange={(e) =>
              handleFilterChange("includeInsurance", e.target.checked)
            }
          />
          Include Insurance
        </label>
        <label className="flex items-center mb-2 text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.includeAirportReception}
            onChange={(e) =>
              handleFilterChange("includeAirportReception", e.target.checked)
            }
          />
          Include Airport Reception
        </label>
        <label className="flex items-center mb-2 text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.includeMeal}
            onChange={(e) =>
              handleFilterChange("includeMeal", e.target.checked)
            }
          />
          Include Meal
        </label>
        <label className="flex items-center mb-2 text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.includeTourGuide}
            onChange={(e) =>
              handleFilterChange("includeTourGuide", e.target.checked)
            }
          />
          Include Tour Guide
        </label>
      </div>
    </>
  );
};

export default PackagesPage;
