import React, { useState } from "react";
import HeaderComponent from "../../homepage/Header/header";
import PackagesPage from "./PackagesPage";
import Footer from "../../../../components/footer";
import Subscription from "../../../../components/subscription";
const Ziyarat = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      {/* <Header/> */}
      <HeaderComponent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <main>
        <PackagesPage
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </main>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Ziyarat;
