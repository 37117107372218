import React from "react";
import { RiCheckboxIndeterminateLine } from "react-icons/ri";
import { TbCheckbox } from "react-icons/tb";

const PackageCard = ({ pkg }) => {
  return (
    <div className="items-center flex flex-col lg:flex-row bg-white shadow-lg rounded-lg overflow-hidden border p-4 gap-x-4">
      {/* Image Section with Fixed Size */}
      <div className="flex-shrink-0 w-48 h-48 lg:w-56 lg:h-56">
        <img
          className="w-full h-full rounded-2xl object-cover"
          src="https://source.unsplash.com/random/240x240?sig=1"
          alt="Packageimage"
        />
      </div>
      {/* Main Package Information */}
      <div className="flex flex-col lg:flex-row gap-x-32 mb-4 mx-auto w-full mt-4 lg:mt-0">
        <div className="lg:w-[60%]">
          {/* Package Details */}
          <h2 className="text-2xl lg:text-3xl font-semibold text-[#4b465c]">
            {pkg.package_name}
          </h2>
          <p className="text-gray-600 text-sm lg:text-base">
            {new Date(pkg.start_date).toLocaleDateString()} to{" "}
            {new Date(pkg.end_date).toLocaleDateString()}
          </p>
          <div style={{ color: "#00936c" }}>
            <span className="mt-4 text-2xl lg:text-3xl font-bold mr-2">
              {pkg.package_cost}
            </span>
            <span className="text-gray-500 text-xs lg:text-sm">
              per person (VAT Excluded)
            </span>
          </div>

          {/* Included/Excluded Sections */}
          <div className="mt-4">
            <h3 className="text-base lg:text-lg font-semibold text-[#4b465c]">
              Included:
            </h3>
            <div className="flex flex-wrap gap-2 my-2">
              {pkg.is_visa_included && <Tag text="Visa" />}
              {pkg.is_insurance_included && <Tag text="Insurance" />}
              {pkg.is_tour_guide_included && <Tag text="Tour Guide" />}
              {pkg.is_airport_reception_included && (
                <Tag text="Airport Reception" />
              )}
              {pkg.is_meal_included && <Tag text="Meal" />}
            </div>

            <h3 className="text-base lg:text-lg font-semibold text-[#4b465c]">
              Excluded:
            </h3>
            <div className="flex flex-wrap gap-2 my-2">
              {!pkg.is_visa_included && <ExcludedTag text="Visa" />}
              {!pkg.is_insurance_included && <ExcludedTag text="Insurance" />}
              {!pkg.is_tour_guide_included && <ExcludedTag text="Tour Guide" />}
              {!pkg.is_airport_reception_included && (
                <ExcludedTag text="Airport Reception" />
              )}
              {!pkg.is_meal_included && <ExcludedTag text="Meal" />}
            </div>
          </div>
        </div>

        {/* Right-side Information */}
        <div className="flex flex-col mt-4 lg:mt-0 lg:ml-4 lg:w-[20%]">
          <div className="flex bg-blue-50 p-2 rounded-lg mb-4 w-[45%] lg:w-full lg:gap-x-2 lg:justify-center items-center">
            <div className="bg-[#00936c] p-1 text-white text-sm font-bold flex items-center justify-center rounded-lg w-8 h-8">
              8.9
            </div>
            <div className="">
              <p className="text-right font-semibold text-sm text-black">
                Fabulous
              </p>
              <p className="text-right text-xs text-gray-500">144 reviews</p>
            </div>
          </div>
          <div className="flex gap-x-2 lg:flex-col lg:gap-y-3">
            <div className="lg:flex bg-gray-100 rounded-lg p-2 text-center w-[45%] lg:w-full">
              <p className="text-gray-600 text-sm lg:text-sm">Mecca Nights</p>
              <p
                className="text-3xl lg:text-3xl font-bold"
                style={{ color: "#00936c" }}
              >
                {pkg.mecca_nights}
              </p>
            </div>
            <div className="lg:flex bg-gray-100 rounded-lg p-2 text-center w-[45%] lg:w-full">
              <p className="text-gray-600 text-sm lg:text-sm">Madinah Nights</p>
              <p
                className="text-3xl lg:text-3xl font-bold"
                style={{ color: "#00936c" }}
              >
                {pkg.madinah_nights}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper component to show included/excluded tags
const Tag = ({ text }) => {
  return (
    <span className=" flex items-center gap-2 px-3 py-1 rounded-lg border bg-green-50 text-green-700 border-green-200">
      <TbCheckbox /> {text}
    </span>
  );
};

// Tag for excluded items
const ExcludedTag = ({ text }) => {
  return (
    <span className="flex items-center gap-2 px-3 py-1 rounded-lg border bg-red-50 text-red-700 border-red-200">
      <RiCheckboxIndeterminateLine /> {text}
    </span>
  );
};

export default PackageCard;
