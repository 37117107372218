import React, { useEffect, useState } from "react";
import Tabs from "./UmrahTabs";
import HajjTabs from "./HajjTabs";
import { getProfileData } from "../../../utility/Api";
import BookingNavbar from "../../../components/Navbar/BookingNavBar";
import Footer from "../../../components/footer";
import Loader from "../../../components/loader";

const profileId = new URLSearchParams(window.location.search).get("profile_id");

const Profile = () => {
  const [umrahPackages, setUmrahPackages] = useState([]);
  const [hajjPackages, setHajjPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [umrahData, hajjData] = await Promise.all([
          getProfileData({ packageType: "Umrah", profileId }),
          getProfileData({ packageType: "Hajj", profileId }),
        ]);
        setUmrahPackages(umrahData.results);
        setHajjPackages(hajjData.results);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  const companyDetail =
    umrahPackages.length > 0 && umrahPackages[0].company_detail
      ? umrahPackages[0].company_detail
      : {};
  return (
    <div>
      <BookingNavbar />
      <div className="space-y-4 text-[#484848] mb-10">
        <div className="items-center">
          <div className="flex items-center justify-center overflow-hidden object-cover">
            <img
              src="https://static.vecteezy.com/system/resources/previews/035/942/012/non_2x/ai-generated-celebration-of-islamic-eid-mubarak-and-eid-al-adha-lantern-in-a-light-background-ai-generated-free-photo.jpg"
              alt={companyDetail.company_name || "Company Image"}
              className="w-full md:h-[283px] h-[200px] object-cover relative"
            />
          </div>
          {companyDetail && (
            <div>
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${companyDetail.company_logo}`}
                alt={companyDetail.company_name}
                className="absolute md:top-[190px] lg:top-[200px] xl:top-[190px] lg:left-[110px] md:left-[55px] left-5 w-20 md:w-[180px] xl:w-[214px] md:rounded-none rounded-full border-[#E5F1EE] border-8 top-[160px]"
              />
            </div>
          )}
          {companyDetail && (
            <div className="md:w-[85%] mx-auto mt-20 px-4 md:px-0 md:space-y-2">
              <div>
                <h1 className="text-[#4B465C] font-bold md:text-3xl xl:text-4xl text-lg">
                  {companyDetail.company_name}
                </h1>
              </div>
              <div className="md:flex md:gap-8 gap-10">
                <div className="flex gap-5">
                  <p className="text-[#4B465C] font-medium md:text-lg text-sm">
                    Islamabad, Pakistan
                  </p>
                  <p className="text-[#4B465C] font-medium md:text-lg text-sm">
                    {companyDetail.total_experience}
                  </p>
                </div>
                <div className="flex items-center mt-2 md:mt-0">
                  <span className="text-white bg-[#003b95] p-1 rounded-md text-sm">
                    4.6
                  </span>
                </div>
              </div>
              <div>
                <h1 className="text-[#4B465C] font-semibold text-sm md:text-lg">
                  {companyDetail.company_bio}
                </h1>
              </div>
            </div>
          )}
        </div>

        {umrahPackages.length > 0 ? (
          <Tabs packages={umrahPackages} />
        ) : (
          <div className="flex justify-center items-center h-[100px]">
            <Loader />
          </div>
        )}

        {hajjPackages.length > 0 ? (
          <HajjTabs packages={hajjPackages} />
        ) : (
          <div className="flex justify-center items-center h-[100px]">
            <Loader />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
