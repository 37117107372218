import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Navbar/Dropdown";
import Currency from "./Navbar/Currency";
import { CurrencyContext } from "../utility/CurrencyContext";
import bg from "../assets/herobg.svg";
import logo from "../assets/logo.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Header.css"; // Create and import a CSS file for responsive styles

const HeaderComponent = ({ isModalOpen, setIsModalOpen }) => {
    const path = window.location.pathname;
    const [isOpen, setIsOpen] = useState(false);
    const { selectedCurrency } = useContext(CurrencyContext);

    const handleClick = () => setIsOpen(true);

    const backgroundImageStyle = {
        backgroundImage: `url(${bg})`,
        backgroundColor: "#E5F1EE",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
    };

    const navItems = [
        { route: "/hajj", label: "Hajj" },
        { route: "/umrah", label: "Umrah" },
        { route: "/error", label: "Ziyarat" },
        { route: "/error", label: "Visa Services" },
        { route: "/transport", label: "Transport" },
    ];

    return (
        <header style={backgroundImageStyle}>
            <div className="mx-auto w-[85%] pb-7">
                <div className="flex flex-col items-start pt-4">
                    <div className="flex justify-between w-full">
                        <NavLink to="/">
                            <div className="flex items-center space-x-6">
                                <img src={logo} alt="Logo" className="lg:h-14 h-10" />
                            </div>
                        </NavLink>
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={handleClick}
                                className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden hover:bg-[#dddddd] transition duration-150"
                            >
                                {selectedCurrency}
                            </button>
                            <a
                                href="http://partners.hajjumrah.co/"
                                className="text-[#00936C] px-3 py-2 rounded-md text-md font-medium lg:flex hidden"
                            >
                                Become A Partner
              </a>
                            {!isOpen && !isModalOpen && <Dropdown isOpenDropdown={handleClick} />}
                        </div>
                    </div>
                    <div className="nav-container lg:flex flex-row lg:justify-start py-0.5 mt-4 w-full">
                        <nav className="flex space-x-6 text-[#827F7F]">
                            {navItems.map(({ route, label }, index) => (
                                <a
                                    key={index}
                                    href={route}
                                    className={`nav-link ${path === route || (route === "/umrah" && path === "/")
                                            ? "active text-gray-800 "
                                            : "hover:text-[#00936C] whitespace-nowrap"
                                        }`}
                                >
                                    {label}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="fixed pt-[1000px] md:pt-[300px] xl:pt-[100px] lg:pb-5 overflow-y-auto inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white w-[85%] p-6 rounded-lg relative">
                        <button
                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                            onClick={() => setIsOpen(false)}
                        >
                            ✖️
            </button>
                        <h2 className="text-lg font-bold mb-6">Select Your Currency</h2>
                        <Currency setIsOpen={setIsOpen} />
                    </div>
                </div>
            )}
        </header>
    );
};

export default HeaderComponent;