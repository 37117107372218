import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import PackageCard from "./PackageCard";
import "rc-slider/assets/index.css";
import Filters from "./Filters";
import SortDropdown from "./Sorting";

const PackagesPage = ({ isModalOpen, setIsModalOpen, packages }) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Scroll to top when the component mounts
    scrollToTop();
  }, []);

  const defaultFilters = {
    packageName: "",
    startDate: "",
    endDate: "",
    costRange: [0, 500000],
    includeVisa: false,
    includeInsurance: false,
    includeAirportReception: false,
    includeTourGuide: false,
    breakfast: false,
    lunch: false,
    dinner: false,
    shuttleService: false,
    airConditioned: false,
    television: false,
    freeWifi: false,
    elevator: false,
    attachedBathroom: false,
    washroomAmenities: false,
    englishToilet: false,
    indianToilet: false,
    laundry: false,
    nightsInMecca: "",
    nightsInMadina: "",
    distanceFromKaabaMecca: [0, 20],
    distanceFromKaabaMadina: [0, 20],
  };

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilterChange = (key, value) =>
    setFilters((prev) => ({ ...prev, [key]: value }));

  const handleSliderChange = (value) => handleFilterChange("costRange", value);

  const areFiltersDefault = (filters) => {
    for (const key in defaultFilters) {
      if (JSON.stringify(filters[key]) !== JSON.stringify(defaultFilters[key])) {
        return false;
      }
    }
    return true;
  };

  const filteredPackages = useMemo(() => {
    if (areFiltersDefault(filters)) {
      return packages;
    }

    const filtered = packages.filter((pkg) => {
      const matchesName = !filters.packageName || pkg.package_name.toLowerCase().includes(filters.packageName.toLowerCase());
      const matchesStartDate = !filters.startDate || new Date(pkg.start_date) >= new Date(filters.startDate);
      const matchesEndDate = !filters.endDate || new Date(pkg.end_date) <= new Date(filters.endDate);
      const matchesCost = pkg.package_base_cost >= filters.costRange[0] && pkg.package_base_cost <= filters.costRange[1];
      const matchesVisa = !filters.includeVisa || pkg.is_visa_included;
      const matchesInsurance = !filters.includeInsurance || pkg.is_insurance_included;
      const matchesReception = !filters.includeAirportReception || pkg.is_airport_reception_included;
      const matchesTourGuide = !filters.includeTourGuide || pkg.is_tour_guide_included;
      const matchesBreakfast = !filters.breakfast || pkg.is_breakfast_included;
      const matchesLunch = !filters.lunch || pkg.is_lunch_included;
      const matchesDinner = !filters.dinner || pkg.is_dinner_included;
      const matchesNightsInMecca = !filters.nightsInMecca || pkg.mecca_nights === parseInt(filters.nightsInMecca);
      const matchesNightsInMadina = !filters.nightsInMadina || pkg.madinah_nights === parseInt(filters.nightsInMadina);

      const matchesHotelFacility = (key, value) => {
        if (!filters[key]) return true;
        const result = pkg.hotel_info_detail.some((hotel) => hotel[value]);
        console.log(`matchesHotelFacility (${key}, ${value}):`, result);
        return result;
      };

      const matchesDistance = (city, key) => {
        if (!filters[key]) return true;
        const result = pkg.hotel_info_detail.some((hotel) => {
          const hotelDistance = parseFloat(hotel.hotel_distance);
          const filterDistance = parseFloat(filters[key][1]);
          const isValidDistance = !isNaN(hotelDistance) && hotelDistance <= filterDistance;
          console.log(`Checking distance for ${city}:`, hotel.hotel_city.toLowerCase() === city.toLowerCase(), hotelDistance, filterDistance, isValidDistance);
          return hotel.hotel_city.toLowerCase() === city.toLowerCase() && isValidDistance;
        });
        console.log(`matchesDistance (${city}, ${key}):`, result);
        return result;
      };

      const result = matchesName &&
        matchesStartDate &&
        matchesEndDate &&
        matchesCost &&
        matchesVisa &&
        matchesInsurance &&
        matchesReception &&
        matchesTourGuide &&
        matchesBreakfast &&
        matchesLunch &&
        matchesDinner &&
        matchesNightsInMecca &&
        matchesNightsInMadina &&
        matchesHotelFacility("shuttleService", "is_shuttle_services_included") &&
        matchesHotelFacility("airConditioned", "is_air_condition") &&
        matchesHotelFacility("television", "is_television") &&
        matchesHotelFacility("freeWifi", "is_wifi") &&
        matchesHotelFacility("elevator", "is_elevator") &&
        matchesHotelFacility("attachedBathroom", "is_attach_bathroom") &&
        matchesHotelFacility("washroomAmenities", "is_washroom_amenities") &&
        matchesHotelFacility("englishToilet", "is_english_toilet") &&
        matchesHotelFacility("indianToilet", "is_indian_toilet") &&
        matchesHotelFacility("laundry", "is_laundry") &&
        matchesDistance("Mecca", "distanceFromKaabaMecca") &&
        matchesDistance("Madinah", "distanceFromKaabaMadina");

      console.log('Package:', pkg.package_name);
      console.log('matchesName:', matchesName);
      console.log('matchesStartDate:', matchesStartDate);
      console.log('matchesEndDate:', matchesEndDate);
      console.log('matchesCost:', matchesCost);
      console.log('matchesVisa:', matchesVisa);
      console.log('matchesInsurance:', matchesInsurance);
      console.log('matchesReception:', matchesReception);
      console.log('matchesTourGuide:', matchesTourGuide);
      console.log('matchesBreakfast:', matchesBreakfast);
      console.log('matchesLunch:', matchesLunch);
      console.log('matchesDinner:', matchesDinner);
      console.log('matchesNightsInMecca:', matchesNightsInMecca);
      console.log('matchesNightsInMadina:', matchesNightsInMadina);
      console.log('Result:', result);

      return result;
    });

    console.log("Filtered packages:", filtered);
    return filtered;
  }, [packages, filters]);

  return (
    <div className="flex flex-col lg:flex-row gap-4 mx-auto w-[85%] py-10">
      <button
        className="lg:hidden bg-[#00936c] text-white py-2 px-4 rounded-lg mb-4"
        onClick={() => setIsModalOpen(true)}
      >
        Filter
      </button>

      <div className="hidden lg:block bg-white shadow-lg rounded-lg p-6 w-full border lg:w-1/4 lg:h-auto lg:min-h-[950px]">
        <Filters
          handleFilterChange={handleFilterChange}
          handleSliderChange={handleSliderChange}
          filters={filters}
        />
      </div>

      <div className="flex-grow grid-cols-1 space-y-6">
        <SortDropdown />
        {filteredPackages.length > 0 ? (
          <div className="flex-grow grid-cols-1 space-y-6">
            {filteredPackages.map((pkg) => (
              <PackageCard
                key={pkg.huz_token}
                pkg={pkg}
                huz_token={pkg.huz_token}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[70vh] text-center text-[#4b465c] md:text-[24px] xl:text-[32px] font-[600] font-kd">
            <img
              src="/images/error.svg"
              alt="No Data"
              className="w-20 lg:w-28 xl:w-32"
            />
            <p>There is no data yet, please try again later.</p>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[90%] max-w-md max-h-screen overflow-y-auto p-6 rounded-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setIsModalOpen(false)}
            >
              ✖️
            </button>
            <h2 className="text-lg font-bold mb-6">Filters</h2>
            <Filters
              handleFilterChange={handleFilterChange}
              handleSliderChange={handleSliderChange}
              filters={filters}
            />
          </div>
        </div>
      )}
    </div>
  );
};

PackagesPage.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
};

export default PackagesPage;
