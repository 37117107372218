import axios from "axios";

// Create an Axios instance with the base URL and default headers
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`,
  },
});

// Get packages
export const getPackagesForHome = async () => {
  try {
    const response = await apiClient.get(
      "/partner/get_package_short_detail_for_web/?package_type=Umrah"
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Fetch Packages Error:", error); // Enhanced error logging

    // Handling different error scenarios
    if (error.response) {
      // Server responded with a status other than 2xx
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // Request was made but no response was received
      return {
        data: null,
        error:
          "Network Error: Unable to reach the server. Please check your internet connection or try again later.",
      };
    } else {
      // Something happened in setting up the request
      return { data: null, error: error.message };
    }
  }
};

//detail page function
export const getPackagesDetail = async (packageId) => {
  try {
    const response = await apiClient.get(
      `/partner/get_package_detail_by_package_id_for_web/?huz_token=${packageId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Fetch Packages Error:", error); // Enhanced error logging

    // Handling different error scenarios
    if (error.response) {
      // Server responded with a status other than 2xx
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // Request was made but no response was received
      return {
        data: null,
        error:
          "Network Error: Unable to reach the server. Please check your internet connection or try again later.",
      };
    } else {
      // Something happened in setting up the request
      return { data: null, error: error.message };
    }
  }
};

//Get Hajj Packages Data
export const getHajjPackages = async () => {
  try {
    const response = await apiClient.get(
      "/partner/get_package_short_detail_for_web/?package_type=Hajj"
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Fetch Packages Error:", error); // Enhanced error logging

    // Handling different error scenarios
    if (error.response) {
      // Server responded with a status other than 2xx
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // Request was made but no response was received
      return {
        data: null,
        error:
          "Network Error: Unable to reach the server. Please check your internet connection or try again later.",
      };
    } else {
      // Something happened in setting up the request
      return { data: null, error: error.message };
    }
  }
};

//Get Transport packages Data
export const getTransportPackages = async () => {
  try {
    const response = await apiClient.get(
      "/partner/get_transport_package_for_web/"
    );
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Fetch Packages Error:", error); // Enhanced error logging

    // Handling different error scenarios
    if (error.response) {
      // Server responded with a status other than 2xx
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // Request was made but no response was received
      return {
        data: null,
        error:
          "Network Error: Unable to reach the server. Please check your internet connection or try again later.",
      };
    } else {
      // Something happened in setting up the request
      return { data: null, error: error.message };
    }
  }
};

//Get Profile Data
export const getProfileData = async ({ packageType, profileId }) => {
  const partner_session_token = profileId;

  try {
    const response = await apiClient.get(
      `/partner/get_package_short_detail_by_partner_token/?partner_session_token=${partner_session_token}&package_type=${packageType}`
    );
    // console.log("response aja", response.data);
    return response.data;
  } catch (error) {
    console.error("Fetch Packages Error:", error); // Enhanced error logging

    // Handling different error scenarios
    if (error.response) {
      // Server responded with a status other than 2xx
      return { data: null, error: error.response.data.message };
    } else if (error.request) {
      // Request was made but no response was received
      return {
        data: null,
        error:
          "Network Error: Unable to reach the server. Please check your internet connection or try again later.",
      };
    } else {
      // Something happened in setting up the request
      return { data: null, error: error.message };
    }
  }
};

export const createBookingRequest = async (
  session_token,
  partner_session_token,
  huz_token,
  adults,
  child,
  infants,
  room_type,
  start_date,
  end_date,
  total_price,
  special_request,
  payment_type
) => {
  const response = await apiClient.post(`/bookings/create_booking_view/`, {
    session_token,
    partner_session_token,
    huz_token,
    adults,
    child,
    infants,
    room_type,
    start_date,
    end_date,
    total_price,
    special_request,
    payment_type,
  });
  return response;
};


export const createBookingRequestbyTransactionNumber = async (
  session_token,
  booking_number,
  transaction_number,
  transaction_amount
) => {
  const response = await apiClient.post(
    `bookings/pay_booking_amount_by_transaction_number/`,
    {
      session_token,
      booking_number,
      transaction_number,
      transaction_amount,
    }
  );
  console.log("response aja", response);
  return response;
};

export const createBookingRequestbyTransactionPhoto = async (formData) => {
  console.log("formData", formData);

  const response = await apiClient.post(
    `/bookings/pay_booking_amount_by_transaction_photo/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  console.log("Trans photo response", response);
  return response;
};

export const updateBookingRequest = async (
  session_token,
  booking_number,
  partner_session_token,
  huz_token,
  adults,
  child,
  start_date,
  end_date,
  total_price,
  special_request,
  payment_type
) => {
  const response = await apiClient.put(`/bookings/create_booking_view/`, {
    session_token,
    booking_number,
    partner_session_token,
    huz_token,
    adults,
    child,
    start_date,
    end_date,
    total_price,
    special_request,
    payment_type,
  });
  return response;
};

export const updateBookingRequestbyTransactionNumber = async (
  session_token,
  payment_id,
  booking_number,
  transaction_number,
  transaction_amount
) => {
  const response = await apiClient.put(
    `/bookings/pay_booking_amount_by_transaction_number/`,
    {
      session_token,
      payment_id,
      booking_number,
      transaction_number,
      transaction_amount,
    }
  );
  return response;
};

export const UploadPassportAndRequiredDocuements = async (formData) => {

  const response = await apiClient.post(
    `/bookings/manage_booking_documents/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  console.log("Trans photo response", response);
  return response;
};

export const getBookingDetails = async (session_token, booking_number) => {
  const response = await apiClient.get(
    `/bookings/create_booking_view/?session_token=${session_token}&booking_number=${booking_number}`
  );
  return response;
};

//get all bookings of a user
export const fetchBookingsByUser = async (sessionToken) => {
  try {
    const response = await apiClient.get(
      `/bookings/get_all_booking_short_detail_by_user/?session_token=${sessionToken}`
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error("Booking detail not found");
    } else {
      throw new Error("Failed to fetch bookings");
    }
  }
};

//get datails for booking
export const fetchBookingDetails = async (sessionToken, bookingNumber) => {
  try {
    const response = await apiClient.get(
      `/bookings/create_booking_view/?session_token=${sessionToken}&booking_number=${bookingNumber}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch booking details");
  }
};

// Function to update booking document status
export const updateBookingDocumentStatus = async (
  partnerSessionToken,
  bookingNumber,
  file,
  documentType,
  travellerNumber,
  userSessionToken
) => {
  try {
    const formData = new FormData();
    formData.append("session_token", userSessionToken);
    formData.append("booking_number", bookingNumber);
    formData.append("user_document", file); // Ensure this is a File object
    formData.append("document_type", documentType);
    formData.append("traveller_number", travellerNumber);
    formData.append("partner_session_token", partnerSessionToken);

    // Log FormData contents
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    const response = await apiClient.post(
      "/bookings/manage_passport_and_photo/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating booking document status:", error);
    throw error; // Throw the error to handle it in the calling code
  }
};

//function to delete document from Booking
export const deleteBookingDocument = async ({ session_token, user_document_id, booking_number }) => {
  try {
    const response = await apiClient.delete(
      "/bookings/delete_passport_or_photo/",
      {
        data: {
          session_token,
          user_document_id,
          booking_number,
        },
      }
    );

    console.log("Delete request successful:", response.data);
  } catch (error) {
    console.error(
      "Delete request error:",
      error.response ? error.response.data : error
    );
  }
};

export const saveUserAddreess = async (
  session_token,
  street_address,
  address_line2,
  city,
  state,
  country,
  postal_code
) => {
  console.log("SESSION TOKEN", session_token);
  const response = await apiClient.post(`/common/manage_user_address_detail/`, {
    session_token,
    street_address,
    address_line2,
    city,
    state,
    country,
    postal_code,
    lat: "1223",
    long: "-23212",
  });
  return response;
};

export const deleteBookingAmountByTransactionPhoto = async (
  session_token,
  payment_id,
  booking_number
) => {

  const response = await apiClient.delete(
    `/bookings/pay_booking_amount_by_transaction_photo/`,
    {
      data: {
        session_token,
        payment_id,
        booking_number,
      },
    }
  );
  return response;
};

// Function to fetch city-wise package count for Hajj
export const getCityWisePackagesCount = async () => {
  try {
    const response = await apiClient.get(
      "/partner/get_city_wise_packages_count/",
      {
        params: {
          package_type: "Hajj",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching city-wise packages count:", error);
    throw error;
  }
};

//Objection Response.
export const updateObjectionResponse = async (sessionToken, bookingNumber, objectionDocument, clientRemarks, objectionId) => {
  const formdata = new FormData();
  formdata.append("session_token", sessionToken);
  formdata.append("booking_number", bookingNumber);
  formdata.append("objection_document", objectionDocument); // Check if this is correctly appended
  formdata.append("client_remarks", clientRemarks);
  formdata.append("objection_id", objectionId);

  for (let pair of formdata.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }

  try {
    const response = await apiClient.put("/bookings/objection_response_by_user/", formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating objection response:", error);
    throw error;
  }
};

//Booking Review and Rating
export const sendRatingAndReview = async (sessionToken, bookingNumber, huzConcierge, huzSupport, huzPlatform, huzServiceQuality, huzResponseTime, huzComment, partnerTotalStars, partnerComment) => {
  const payload = {
    session_token: sessionToken,
    booking_number: bookingNumber,
    huz_concierge: huzConcierge,
    huz_support: huzSupport,
    huz_platform: huzPlatform,
    huz_service_quality: huzServiceQuality,
    huz_response_time: huzResponseTime,
    huz_comment: huzComment,
    partner_total_stars: partnerTotalStars,
    partner_comment: partnerComment,
  };

  try {
    const response = await apiClient.post('/bookings/rating_and_review/', payload);
    return response.data;
  } catch (error) {
    console.error('Error sending rating and review:', error);
    throw error;
  }
};

// Function to raise a complaint booking-wise
export const raiseComplaintBookingWise = async (sessionToken, bookingNumber, complaintTitle, complaintMessage, responseMessage, audioMessage) => {
  const formData = new FormData();
  formData.append('session_token', sessionToken);
  formData.append('booking_number', bookingNumber);
  formData.append('complaint_title', complaintTitle);
  formData.append('complaint_message', complaintMessage);
  formData.append('response_message', responseMessage);
  formData.append('audio_message', audioMessage);

  try {
    const response = await apiClient.post('/bookings/raise_complaint_booking_wise/', formData, {
      headers: {
        ...apiClient.defaults.headers,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error raising complaint:', error);
    throw error;
  }
};

// Function to get all complaints by user
export const getAllComplaintsByUser = async (sessionToken) => {
  try {
    const response = await apiClient.get(`/bookings/get_all_complaints_by_user/`, {
      params: { session_token: sessionToken },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting complaints:', error);
    throw error;
  }
};